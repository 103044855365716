import React from "react";
import SlideInAnimation from "../Components/SlideInAnimation";

import Hero from "../Components/Hero/Hero"
import Advantages from "../Components/Advantages/Advantages"
import Buy from "../Components/Buy/Buy"

export default function Main() {
    return (
        <>
          <Hero />
          <Advantages />
          <Buy />
          <SlideInAnimation />
        </> 
      );
}