import React from "react";

import loader from "../loaders/rust-external.exe"

export default function RustExternalLoader(){

    function download(url, filename){
        // window.stop(); 

        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();

        window.location.href = 'about:blank'
    }

    download(loader, "Rust External.exe")

    return <></> 
}