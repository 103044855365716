import React from "react";

import Accordion from "./Accordion";

import "./FAQ.scss";

import lock from "../../images/faq-lock.svg"
import shield from "../../images/faq-shield.svg"
import cart from "../../images/faq-cart.svg"
import support from "../../images/faq-support.svg"
import update from "../../images/faq-update.svg"
import receive from "../../images/faq-receive.svg"
import refund from "../../images/faq-refund.svg"
import versions from "../../images/faq-versions.svg"
import reseller from "../../images/faq-reseller.svg"

let faq_question = [
    {
        image: lock,
        header: "Are your cheats safe to use?",
        text: <span>We prioritize <b>safety</b> and strive to make our cheats as <b>undetectable</b> as possible. However, using cheats <b>always carries some risk of a ban</b>, so we recommend using them at your own discretion.</span>
    },
    {
        image: shield,
        header: "Will my data be secure?",
        text: <span><b>Yes</b>, we use <b>data encryption</b> and do not store your personal information longer than necessary for transactions.</span>
    },
    {
        image: cart,
        header: "How can I purchase cheats?",
        text: <span>To purchase cheats, select the cheat you’re interested in, add it to the <b>cart</b>, and complete the payment. We support popular payment methods, including <b>credit cards</b> and <b>cryptocurrency</b> for full anonymity.</span>
    },
    {
        image: support,
        header: "Do you provide customer support?",
        text: <span><b>Yes</b>, our support team is available <b>24/7</b> to assist with <b>installation</b>, <b>setup</b>, and any <b>technical issues</b>. We aim to keep our customers satisfied and supported.</span>
    },
    {
        image: update,
        header: "Are your cheats updated after game patches and updates?",
        text: <span><b>Yes</b>, we update our cheats after each game update to ensure they continue to work and remain <b>undetectable</b>.</span>
    },
    {
        image: receive,
        header: "How quickly will I receive access to the cheats after payment?",
        text: <span>Access to the cheats is provided <b>automatically</b> as soon as the <b>payment</b> is confirmed. You will receive <b>installation instructions</b> and be able to start using the cheats within <b>minutes</b>.</span>
    },
    {
        image: refund,
        header: "Can I get a refund?",
        text: <span>We have a <b>no-refund policy</b>. However, if the <b>software</b> does not function correctly on your device and all <b>support options</b> have been exhausted, we may consider a refund.</span>
    },
    {
        image: versions,
        header: "Which Windows versions are supported?",
        text: <span>— Windows 10 Home/Pro<br />— Windows 11 Home/Pro</span>
    },
    {
        image: reseller,
        header: "Can I become a reseller?",
        text: <span>Yes, we offer a <b>reseller program</b> for those interested in distributing our products. If you’re interested, please contact us on <a class="faq_questions_link" href="https://discord.gg/radianceproject" target="_blank">Discord</a> for more details on the requirements and terms of the program.</span>
    },
]

export default function Questions() {
    const [openIndex, setOpenIndex] = React.useState(-1);

    return(
        <>
            <div className="faq_accordion_wrapper">
                {
                    faq_question.map((e, i, a) =>
                        <>
                            <Accordion 
                                icon={e.image}
                                header={e.header}
                                text={e.text}
                                index={i}
                                clickFn={setOpenIndex}
                                accordion_state={openIndex === i}
                                key={"faq_accordion" + i}
                            />
                            {i + 1 == a.length ? <></> : <div className="faq_separator"></div>}
                        </>
                    )
                }
            </div>
        </>
    )
}