import React from "react"
import { motion } from "framer-motion"
// import logo from "../images/radiance_logo.svg";

export default function SlideInAnimation() {
    const [startAnimation, setStartAnimation] = React.useState(false);

    // React.useEffect(() => {
    //     let line = document.querySelector(".line4")
    //     console.log(line.getTotalLength())
    // })

    return(
        <>
            <motion.div 
                    className="slideIn"
                    initial={{top: "110vh"}}
                    animate={{top: "110vh"}}
                    exit={{top: "0%"}}
                    onAnimationStart={() => {
                        setStartAnimation(true)
                        setTimeout(() => {
                            setStartAnimation(false)
                        }, 1200)
                    }}
                    transition={{duration: 0.5, ease: [0.27,0.94,0.48,1.0]}}
                >
                    <svg className={`logo_svg_animate ${startAnimation ? " active" : ""}`} width="355" height="305" viewBox="0 0 355 305" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="clip-mask">
                                <rect x="0" y="0" width="100%" height="100%" fill="white" />
                            </clipPath>
                        </defs>
                        
                        <path className="line1" d="M28 295V6" stroke="white" strokeWidth="56"/>
                        <path className="line2" d="M145 176L149.5 175C171 171.5 211.5 157 215 108.5C218.716 57 175 34 151.5 34H56H54" stroke="white" strokeWidth="56"/>
                        <path className="line3" d="M118 149C120.5 191.5 138 258.107 210 273.5C266.5 285.579 321.5 253.5 334.5 240" stroke="white" strokeWidth="56"/>
                        <path className="line4" d="M276.335 4.21508C277.378 4.43306 279.467 4.85256 279.467 4.85256C279.467 4.85256 285.692 6.46701 288.768 7.4791C289.589 7.74669 290.414 8.00281 291.239 8.25818C298.358 10.5119 305.209 13.601 311.812 17.0754C312.217 17.2875 312.622 17.4996 313.04 17.7181C324.709 23.9544 334.921 31.6672 344.802 40.4107C345.425 40.9453 346.077 41.4445 346.734 41.9368C347.71 42.7769 349.5 44.5 349.537 44.8679C349.574 45.2358 348.839 46.0111 348.839 46.0111L344.406 50.3348L327.665 66.6874C323.683 70.3957 319.915 74.6745 315.803 78.1064C314.882 78.8817 313.142 80.49 313.142 80.49L307.377 76.4989L304.938 74.4756C304.938 74.4756 302.785 72.6309 301.328 71.5498C299.113 69.9068 297.321 69.0729 295.393 67.7215C293.088 66.1779 290.668 65.022 288.142 63.888C287.676 63.6773 287.209 63.4666 286.729 63.2495C280.191 60.3314 273.614 57.9863 266.645 56.3284C266.037 56.1826 265.431 56.0287 264.827 55.8642C262.602 55.295 260.392 55.0884 258.106 54.9253C257.105 54.8449 256.105 54.7629 255.104 54.6804C254.419 54.6287 253.733 54.5852 253.046 54.5496C251.228 54.4554 249 54.5 247 54C246 52 245.035 50 243.962 48.1175C240.929 42.7918 237.504 38.0472 233.763 33.2339C233.574 32.9896 233.385 32.7452 233.191 32.4934C230.632 29.1841 228.01 26.3235 224.849 23.5852C223.783 22.6422 222.796 21.6293 221.789 20.6231C221.055 19.9347 220.315 19.2515 219.572 18.5721C219.206 18.2371 218.84 17.9021 218.463 17.557C215.953 15.3433 213.266 13.8816 210.647 11.8648C208.606 10.3445 206.532 9.08154 204.276 7.90653C203.134 7.30355 202.021 6.70693 200.947 5.98892C200.5 5.5 200.5 5.5 200.947 5.102C225.111 -0.643577 252.16 -2.28627 276.335 4.21508Z" fill="white"/>
                    </svg>
                </motion.div>
                <motion.div 
                    className="slideOut"
                    initial={{top: "0%"}}
                    animate={{top: "-100%"}}
                    exit={{top: "-100%"}}
                    transition={{duration: 0.5, delay: 1.2, ease: [0.27,0.94,0.48,1.0]}}
                >
                    {/* <motion.img 
                        initial={{opacity: 1}}
                        animate={{opacity: 0}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.5, ease: [0.27,0.94,0.48,1.0]}}
                        src={logo} alt="" 
                    /> */}
                    <svg className="logo_svg_animate" width="355" height="305" viewBox="0 0 355 305" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="clip-mask2">
                                <rect x="0" y="0" width="100%" height="100%" fill="white" />
                            </clipPath>
                        </defs>
                        
                        <path className="line1" d="M28 295V6" stroke="white" strokeWidth="56"/>
                        <path className="line2" d="M145 176L149.5 175C171 171.5 211.5 157 215 108.5C218.716 57 175 34 151.5 34H56H54" stroke="white" strokeWidth="56"/>
                        <path className="line3" d="M118 149C120.5 191.5 138 258.107 210 273.5C266.5 285.579 321.5 253.5 334.5 240" stroke="white" strokeWidth="56"/>
                        <path className="line4" d="M276.335 4.21508C277.378 4.43306 279.467 4.85256 279.467 4.85256C279.467 4.85256 285.692 6.46701 288.768 7.4791C289.589 7.74669 290.414 8.00281 291.239 8.25818C298.358 10.5119 305.209 13.601 311.812 17.0754C312.217 17.2875 312.622 17.4996 313.04 17.7181C324.709 23.9544 334.921 31.6672 344.802 40.4107C345.425 40.9453 346.077 41.4445 346.734 41.9368C347.71 42.7769 349.5 44.5 349.537 44.8679C349.574 45.2358 348.839 46.0111 348.839 46.0111L344.406 50.3348L327.665 66.6874C323.683 70.3957 319.915 74.6745 315.803 78.1064C314.882 78.8817 313.142 80.49 313.142 80.49L307.377 76.4989L304.938 74.4756C304.938 74.4756 302.785 72.6309 301.328 71.5498C299.113 69.9068 297.321 69.0729 295.393 67.7215C293.088 66.1779 290.668 65.022 288.142 63.888C287.676 63.6773 287.209 63.4666 286.729 63.2495C280.191 60.3314 273.614 57.9863 266.645 56.3284C266.037 56.1826 265.431 56.0287 264.827 55.8642C262.602 55.295 260.392 55.0884 258.106 54.9253C257.105 54.8449 256.105 54.7629 255.104 54.6804C254.419 54.6287 253.733 54.5852 253.046 54.5496C251.228 54.4554 249 54.5 247 54C246 52 245.035 50 243.962 48.1175C240.929 42.7918 237.504 38.0472 233.763 33.2339C233.574 32.9896 233.385 32.7452 233.191 32.4934C230.632 29.1841 228.01 26.3235 224.849 23.5852C223.783 22.6422 222.796 21.6293 221.789 20.6231C221.055 19.9347 220.315 19.2515 219.572 18.5721C219.206 18.2371 218.84 17.9021 218.463 17.557C215.953 15.3433 213.266 13.8816 210.647 11.8648C208.606 10.3445 206.532 9.08154 204.276 7.90653C203.134 7.30355 202.021 6.70693 200.947 5.98892C200.5 5.5 200.5 5.5 200.947 5.102C225.111 -0.643577 252.16 -2.28627 276.335 4.21508Z" fill="white"/>
                    </svg>
            </motion.div>
        </>
    )
}