import React from "react";

import SlideInAnimation from "../Components/SlideInAnimation";
import { motion, AnimatePresence } from "framer-motion"
import { useLocation, Link } from "react-router-dom";

export default function FAQ({children}) {
    let FAQRef = React.useRef();

    const location = useLocation()
    const changeDirectory = location.pathname === '/instructions'

    React.useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting === true) {
                    let classList = entry.target.className;
                    let newClassName = classList.replaceAll(" active", "");
                    entry.target.className = newClassName + " active";
                    observer.unobserve(entry.target);
                }
            })  
        }, {threshold: 0.3})
        
        observer.observe(FAQRef.current)
    }, [])

    return(
        <>
            <div className="faq box">
                <div className="container" ref={FAQRef}>
                    <div className="box-gradient"></div>
                    <div className="faq_title">
                        <h1 className="faq_title_main">Frequently asked questions</h1>
                        <p className="faq_title_sub">
                            <span>These are the most commonly asked questions about Radiance.</span>
                            <span>Can't find what you're looking for? <a href="https://discord.com/invite/radianceproject/" target="_blank" rel="noreferrer">Chat our friendly support!</a></span>
                        </p>
                    </div>
                    <div className="faq_section_container">
                        <div className="faq_section_select">
                            <div className="modal-content region">
                                <div className="content-options">
                                    <Link to="/faq">
                                        <span className={changeDirectory === false ? "active" : ""} >General</span>
                                    </Link>
                                    <Link to="/instructions">
                                        <span className={changeDirectory === true ? "active" : ""}>Instructions</span>
                                    </Link>

                                    <div className="active-indicator" style={changeDirectory === false ? {left : "4px"} : {left: "50%"}}></div>
                                </div>
                            </div>
                        </div>
                        <AnimatePresence initial={false}>
                            <motion.div transition={{duration: 0.4}} 
                                initial={{opacity: 0, display: "none"}}
                                animate={{opacity: 1, transition: {duration: 0.2, delay: 0.2}, display: "block"}}
                                exit={{opacity: 0, transition:{duration: 0.2}, display: "none"}}
                                key={location.pathname}
                            >
                                {children}
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            <SlideInAnimation />
        </>
    )
}